@font-face {
	font-family: 'Nunito';
	src: url('../../assets/font/nunito/Nunito-Italic.eot');
	src: url('../../assets/font/nunito/Nunito-Italic.eot?#iefix') format('embedded-opentype'), url('../../assets/font/nunito/Nunito-Italic.woff2') format('woff2'),
		url('../../assets/font/nunito/Nunito-Italic.woff') format('woff'), url('../../assets/font/nunito/Nunito-Italic.ttf') format('truetype');
	font-weight: normal;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Nunito';
	src: url('../../assets/font/nunito/Nunito-Light.eot');
	src: url('../../assets/font/nunito/Nunito-Light.eot?#iefix') format('embedded-opentype'), url('../../assets/font/nunito/Nunito-Light.woff2') format('woff2'),
		url('../../assets/font/nunito/Nunito-Light.woff') format('woff'), url('../../assets/font/nunito/Nunito-Light.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Nunito';
	src: url('../../assets/font/nunito/Nunito-BoldItalic.eot');
	src: url('../../assets/font/nunito/Nunito-BoldItalic.eot?#iefix') format('embedded-opentype'),
		url('../../assets/font/nunito/Nunito-BoldItalic.woff2') format('woff2'), url('../../assets/font/nunito/Nunito-BoldItalic.woff') format('woff'),
		url('../../assets/font/nunito/Nunito-BoldItalic.ttf') format('truetype');
	font-weight: bold;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Nunito';
	src: url('../../assets/font/nunito/Nunito-Regular.eot');
	src: url('../../assets/font/nunito/Nunito-Regular.eot?#iefix') format('embedded-opentype'),
		url('../../assets/font/nunito/Nunito-Regular.woff2') format('woff2'), url('../../assets/font/nunito/Nunito-Regular.woff') format('woff'),
		url('../../assets/font/nunito/Nunito-Regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Nunito';
	src: url('../../assets/font/nunito/Nunito-BlackItalic.eot');
	src: url('../../assets/font/nunito/Nunito-BlackItalic.eot?#iefix') format('embedded-opentype'),
		url('../../assets/font/nunito/Nunito-BlackItalic.woff2') format('woff2'), url('../../assets/font/nunito/Nunito-BlackItalic.woff') format('woff'),
		url('../../assets/font/nunito/Nunito-BlackItalic.ttf') format('truetype');
	font-weight: 900;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Nunito';
	src: url('../../assets/font/nunito/Nunito-ExtraLight.eot');
	src: url('../../assets/font/nunito/Nunito-ExtraLight.eot?#iefix') format('embedded-opentype'),
		url('../../assets/font/nunito/Nunito-ExtraLight.woff2') format('woff2'), url('../../assets/font/nunito/Nunito-ExtraLight.woff') format('woff'),
		url('../../assets/font/nunito/Nunito-ExtraLight.ttf') format('truetype');
	font-weight: 200;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Nunito';
	src: url('../../assets/font/nunito/Nunito-ExtraBoldItalic.eot');
	src: url('../../assets/font/nunito/Nunito-ExtraBoldItalic.eot?#iefix') format('embedded-opentype'),
		url('../../assets/font/nunito/Nunito-ExtraBoldItalic.woff2') format('woff2'), url('../../assets/font/nunito/Nunito-ExtraBoldItalic.woff') format('woff'),
		url('../../assets/font/nunito/Nunito-ExtraBoldItalic.ttf') format('truetype');
	font-weight: bold;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Nunito';
	src: url('../../assets/font/nunito/Nunito-ExtraLightItalic.eot');
	src: url('../../assets/font/nunito/Nunito-ExtraLightItalic.eot?#iefix') format('embedded-opentype'),
		url('../../assets/font/nunito/Nunito-ExtraLightItalic.woff2') format('woff2'), url('../../assets/font/nunito/Nunito-ExtraLightItalic.woff') format('woff'),
		url('../../assets/font/nunito/Nunito-ExtraLightItalic.ttf') format('truetype');
	font-weight: 200;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Nunito';
	src: url('../../assets/font/nunito/Nunito-Black.eot');
	src: url('../../assets/font/nunito/Nunito-Black.eot?#iefix') format('embedded-opentype'), url('../../assets/font/nunito/Nunito-Black.woff2') format('woff2'),
		url('../../assets/font/nunito/Nunito-Black.woff') format('woff'), url('../../assets/font/nunito/Nunito-Black.ttf') format('truetype');
	font-weight: 900;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Nunito';
	src: url('../../assets/font/nunito/Nunito-Bold.eot');
	src: url('../../assets/font/nunito/Nunito-Bold.eot?#iefix') format('embedded-opentype'), url('../../assets/font/nunito/Nunito-Bold.woff2') format('woff2'),
		url('../../assets/font/nunito/Nunito-Bold.woff') format('woff'), url('../../assets/font/nunito/Nunito-Bold.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Nunito';
	src: url('../../assets/font/nunito/Nunito-LightItalic.eot');
	src: url('../../assets/font/nunito/Nunito-LightItalic.eot?#iefix') format('embedded-opentype'),
		url('../../assets/font/nunito/Nunito-LightItalic.woff2') format('woff2'), url('../../assets/font/nunito/Nunito-LightItalic.woff') format('woff'),
		url('../../assets/font/nunito/Nunito-LightItalic.ttf') format('truetype');
	font-weight: 300;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Nunito';
	src: url('../../assets/font/nunito/Nunito-Medium.eot');
	src: url('../../assets/font/nunito/Nunito-Medium.eot?#iefix') format('embedded-opentype'), url('../../assets/font/nunito/Nunito-Medium.woff2') format('woff2'),
		url('../../assets/font/nunito/Nunito-Medium.woff') format('woff'), url('../../assets/font/nunito/Nunito-Medium.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Nunito';
	src: url('../../assets/font/nunito/Nunito-SemiBoldItalic.eot');
	src: url('../../assets/font/nunito/Nunito-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),
		url('../../assets/font/nunito/Nunito-SemiBoldItalic.woff2') format('woff2'), url('../../assets/font/nunito/Nunito-SemiBoldItalic.woff') format('woff'),
		url('../../assets/font/nunito/Nunito-SemiBoldItalic.ttf') format('truetype');
	font-weight: 600;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Nunito';
	src: url('../../assets/font/nunito/Nunito-ExtraBold.eot');
	src: url('../../assets/font/nunito/Nunito-ExtraBold.eot?#iefix') format('embedded-opentype'),
		url('../../assets/font/nunito/Nunito-ExtraBold.woff2') format('woff2'), url('../../assets/font/nunito/Nunito-ExtraBold.woff') format('woff'),
		url('../../assets/font/nunito/Nunito-ExtraBold.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Nunito';
	src: url('../../assets/font/nunito/Nunito-SemiBold.eot');
	src: url('../../assets/font/nunito/Nunito-SemiBold.eot?#iefix') format('embedded-opentype'),
		url('../../assets/font/nunito/Nunito-SemiBold.woff2') format('woff2'), url('../../assets/font/nunito/Nunito-SemiBold.woff') format('woff'),
		url('../../assets/font/nunito/Nunito-SemiBold.ttf') format('truetype');
	font-weight: 600;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Nunito';
	src: url('../../assets/font/nunito/Nunito-MediumItalic.eot');
	src: url('../../assets/font/nunito/Nunito-MediumItalic.eot?#iefix') format('embedded-opentype'),
		url('../../assets/font/nunito/Nunito-MediumItalic.woff2') format('woff2'), url('../../assets/font/nunito/Nunito-MediumItalic.woff') format('woff'),
		url('../../assets/font/nunito/Nunito-MediumItalic.ttf') format('truetype');
	font-weight: 500;
	font-style: italic;
	font-display: swap;
}
