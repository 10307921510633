.reinsurance {
	&--item {
		padding: 30px 20px;
		background: $WHITE;
		border-radius: 10px;

		&-head {
			display: flex;
			margin-bottom: 20px;
			flex-flow: row nowrap;
			justify-content: center;
			align-items: center;
		}

		&-content {
			display: flex;
			flex-flow: row wrap;
			justify-content: center;
			align-items: top;

			* {
				width: 100%;
				text-align: center;
			}

			p {
				margin: 0;
				@include fontSize(20);
				font-weight: 700;

				& + p {
					margin-top: 20px;
				}

				@media (max-width: $md) {
					@include fontSize(16);
				}
			}

			[class^='button-'] {
				margin-top: 20px;
				width: max-content;
			}
		}

		&-icon {
			max-height: 40px;
			margin-left: 15px;

			@media (max-width: $md) {
				max-height: 30px;
			}
		}

		&-title {
			margin: 0;
			@include fontSize(40);
			font-weight: 700;
			color: $ORANGE;

			@media (max-width: $md) {
				@include fontSize(30);
			}
		}
	}

	&--title {
		width: 100%;
		margin: 0 0 30px;
		text-align: center;
		@include fontSize(40);
		font-weight: 700;
		color: $ORANGE;

		@media (max-width: $md) {
			@include fontSize(30);
		}
	}

	.row {
		row-gap: 32px;
		column-gap: 32px;
	}
}
