.superposition {
	position: relative;
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: center;
	width: 100%;

	@media (max-width: $md) {
		flex-flow: column nowrap;
	}

	&-left {
		.superposition-body {
			right: 0;
		}
		@media (min-width: $md) {
			justify-content: flex-start;
			.superposition-body {
				right: 0;
			}
		}
	}

	&-right {
		.superposition-body {
			left: 0;
		}
		@media (min-width: $md) {
			justify-content: flex-end;
			.superposition-body {
				left: 0;
			}
		}
	}

	&-small {
		position: relative;
		width: 40%;
		aspect-ratio: 1;
		background: rgba($WHITE, 0.5);
		border-radius: 20px;
		overflow: hidden;

		@media (max-width: $md) {
			width: 100%;
		}

		&-content {
			display: flex;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			flex-flow: row nowrap;
			justify-content: center;
			align-items: center;
		}
	}

	&-body {
		position: absolute;
		display: flex;
		top: 0;
		width: 65%;
		height: 100%;
		flex-flow: column nowrap;
		justify-content: center;

		@media (max-width: $md) {
			position: relative;
			width: 100%;
			margin-top: -10%;
		}

		&-content {
			width: 100%;
			padding: 30px;
			background: $WHITE;
			border-radius: 20px;
		}
	}

	&-title {
		margin: 0;
		width: 100%;
		color: $ORANGE;
		@include fontSize(40);

		@media (max-width: $md) {
			@include fontSize(30);
		}
	}

	&-content {
		margin-top: 20px;

		p,
		a {
			margin: 0;
		}

		p + p {
			margin-top: 20px;
		}
	}
}
