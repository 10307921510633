.cookies-banner {
	position: fixed;
	bottom: 20px;
	left: 20px;
	width: 100%;
	max-width: 400px;
	padding: 10px;
	background: $WHITE;
	border-radius: 20px;
	box-shadow: 0px 8px 12px -3px rgba($BLACK, 0.39);
	z-index: 9999;

	@media (max-width: $md) {
		bottom: 0;
		left: 0;
		width: 100%;
		max-width: 100%;
		border-radius: 0;

		.button {
			display: flex;
			flex-flow: row wrap;
			justify-content: space-between;
		}
	}

	&--title {
		margin-top: 0;
		@include fontSize(20);
		font-weight: 800;
		color: $ORANGE;
	}

	&--text {
		@include fontSize(14);
		color: $BLACK;
		margin-bottom: 20px;
	}

	&--link {
		@include fontSize(14);
	}

	.button {
		margin-top: 10px;
		@include fontSize(14);
	}
}
