.page-link {
	display: flex;
	padding: 20px 40px;
	flex-flow: row wrap;
	justify-content: center;
	align-items: center;
	background: $WHITE;
	text-decoration: none;
	border-radius: 6px;
	cursor: pointer;

	&--logo {
		width: 100%;
		max-width: 120px;
		margin: 0;
		aspect-ratio: 1/1;
		border-radius: 6px;
		overflow: hidden;

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			object-position: center;
		}
	}

	&--name {
		width: 100%;
		margin: 20px 0 0;
		text-align: center;
		@include fontSize(20);
		font-weight: 700;
		color: $BLACK;

		@media (max-width: $md) {
			@include fontSize(16);
		}
	}
}
