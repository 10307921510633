.page-place-search {
	&--header {
		padding-bottom: 0;

		.search-field {
			display: grid;
			grid-template-columns: 1fr 1fr max-content;
			column-gap: 40px;
			align-items: flex-end;

			.form-group {
				margin: 0;

				& + .form-group {
					margin-top: 0;
				}
			}

			@media (max-width: $md) {
				grid-template-columns: 1fr;
				row-gap: 40px;
			}
		}
	}

	&--results {
		padding: 80px 0 0;

		.loader {
			margin: 0 auto;
			max-width: 150px;
		}

		.load-more {
			display: block;
			margin: 0 auto;
			max-width: max-content;
		}
	}

	&--grid {
		display: grid;
		grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
		column-gap: 16px;
		row-gap: 16px;

		@media (max-width: $md) {
			grid-template-columns: repeat(2, 1fr);
		}

		@media (max-width: $sm) {
			grid-template-columns: 1fr;
		}

		&-item {
			padding: 15px;
			background: $WHITE;
			border-radius: 5px;
			text-decoration: none;
			color: $BLACK;

			&-head {
				.place-name {
					display: flex;
					margin-bottom: 5px;
					flex-flow: column nowrap;

					img {
						width: 90px;
						height: 90px;
						border-radius: 5px;
						object-fit: cover;
						object-position: center;
						flex-shrink: 0;
					}

					h3 {
						margin: 10px 0 0;
						@include fontSize(20);
					}

					p {
						margin: 0;
					}
				}

				.place-address {
					margin: 10px 0 0;
				}

				.stat-counter--value {
					font-weight: 300;
				}
			}

			&-body {
				margin: 10px 0 0;

				p {
					margin: 0;
					font-weight: 600;
				}
			}
		}
	}

	&--title {
		margin: 40px 0 0;
		text-align: center;
	}

	&--sub-title {
		margin: 0 0 80px;
		text-align: center;
	}

	&--no-result {
		text-align: center;
	}

	&--loader {
		display: flex;
		width: 100%;
		height: 100vh;
		flex-flow: row nowrap;
		align-items: center;
		justify-content: center;
		.loader {
			max-width: 500px;
		}
	}
}
