.switch {
	position: relative;
	width: 100%;

	.switch--label {
		display: flex;
		width: 100%;
		flex-flow: row nowrap;
		justify-content: space-between;
		align-items: center;

		&-text {
			font-weight: 700;
		}
	}

	&--input {
		&:checked + .switch--label {
			.switch--container {
				background-color: $ORANGE;
			}

			.switch--cursor {
				left: calc(100% - 24px - 4px);
			}
		}
	}

	&--container {
		position: relative;
		display: inline-block;
		width: 52px;
		height: 32px;
		flex-shrink: none;
		transition: background-color 0.5s ease;
		background-color: $BLACK;
		border-radius: 50px;
		cursor: pointer;
	}

	&--cursor {
		position: absolute;
		width: 24px;
		height: 24px;
		top: calc(50% - 12px);
		left: 4px;
		background: $WHITE;
		border-radius: 50%;
		transition: all 0.5s ease;
	}

	&--input {
		position: absolute;
		top: 0;
		left: 0;
		opacity: 0;
	}
}
