.image-upload {
	position: relative;

	&--input {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		opacity: 0;
		cursor: pointer;
	}

	&--container {
		display: block;
	}

	&--preview {
		width: 150px;
		height: 150px;
		margin: 10px 0;
		border-radius: 6px;
		overflow: hidden;

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			object-position: center;
		}
	}

	&--instruction {
		font-style: italic;
		@include fontSize(14);
	}
}
