.downloads {
	&--head {
		width: 100%;

		&-title {
			width: 100%;
			margin: 0;
			text-align: center;
			@include fontSize(40);
			font-weight: 700;
			color: $ORANGE;
			@media (max-width: $md) {
				@include fontSize(30);
			}
		}

		&-sub-title {
			width: 100%;
			margin: 30px 0 0;
			text-align: center;
			@include fontSize(20);
			font-weight: 700;

			@media (max-width: $md) {
				@include fontSize(16);
			}
		}
	}

	&--links {
		width: 100%;
		margin-top: 60px;

		&-list {
			display: flex;
			width: 100%;
			margin: 0;
			padding: 0;
			flex-flow: row nowrap;
			justify-content: center;
			align-items: center;
			list-style: none;

			@media (max-width: $md) {
				flex-flow: column nowrap;
			}
		}

		&-item {
			display: inline-block;
			width: 125px;

			a {
				display: block;
				width: 100%;

				img {
					width: 100%;
				}
			}

			& + & {
				margin-left: 50px;

				@media (max-width: $md) {
					margin-left: 0;
					margin-top: 20px;
				}
			}
		}
	}

	&--mockup {
		max-width: 855px;
		margin-top: 60px;

		img {
			width: 100%;
		}
	}

	.container {
		justify-content: center;
	}
}
