.header {
	min-height: 100vh;

	.container,
	.row {
		min-height: 100vh;
	}

	[class^='col'] {
		height: 100%;
		display: flex;
		flex-flow: row wrap;
		align-content: center;
		justify-content: center;

		@media (max-width: $md) {
			&:first-child {
				grid-row: 2;
			}

			&:last-child {
				grid-row: 1;
			}
		}
	}

	&-title {
		width: 100%;
		margin: 10px 0;
		white-space: pre-line;
		@include fontSize(40);
		color: $ORANGE;

		@media (max-width: $md) {
			@include fontSize(30);
		}
	}

	&-sub-title {
		width: 100%;
		margin: 10px 0;
		white-space: pre-line;
		@include fontSize(24);
		color: $BLACK;
		font-weight: 400;
	}

	&-image {
		width: 100%;
	}
}
