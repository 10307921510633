.hero-parallax {
	display: flex;
	width: 100vw;
	min-height: 100vh;
	flex-flow: row wrap;
	justify-content: center;
	align-content: center;

	&--title {
		width: 100%;
		margin-top: 30%;
		margin-bottom: 70px;
		text-align: center;
		@include fontSize(40);
		font-weight: 700;
		color: $ORANGE;

		@media (max-width: $md) {
			@include fontSize(30);
		}
	}

	&--content {
		display: flex;
		width: 100%;
		flex-flow: row wrap;
		justify-content: center;
		align-items: center;
		overflow: hidden;
	}

	&--images {
		position: relative;
	}

	&--image {
		&-front {
			max-width: 540px;
			width: 100%;
		}
		&-back {
			position: absolute;
			width: 100%;
			max-width: 400px;
			top: 50%;
			right: -50%;
			z-index: -1;
			transition: all 0.1s;

			@media (max-width: $sm) {
				display: none;
			}
		}
	}
}
