.map {
	min-height: 370px;
	border: 6px solid $WHITE;

	.marker {
		border: none;
		height: 56px;
		width: 56px;
		background-image: url('../../assets/images/icons/map-pin.png');
		background-position: center;
		background-repeat: no-repeat;
		background-size: contain;
	}
}
