// variables
// --------------------------

$fa-css-prefix: fa !default;
$fa-style: 900 !default;
$fa-style-family: 'Font Awesome 6 Pro' !default;

$fa-display: inline-block !default;

$fa-fw-width: fa-divide(20em, 16) !default;
$fa-inverse: #fff !default;

$fa-border-color: #eee !default;
$fa-border-padding: 0.2em 0.25em 0.15em !default;
$fa-border-radius: 0.1em !default;
$fa-border-style: solid !default;
$fa-border-width: 0.08em !default;

$fa-size-scale-2xs: 10 !default;
$fa-size-scale-xs: 12 !default;
$fa-size-scale-sm: 14 !default;
$fa-size-scale-base: 16 !default;
$fa-size-scale-lg: 20 !default;
$fa-size-scale-xl: 24 !default;
$fa-size-scale-2xl: 32 !default;

$fa-sizes: (
	'2xs': $fa-size-scale-2xs,
	'xs': $fa-size-scale-xs,
	'sm': $fa-size-scale-sm,
	'lg': $fa-size-scale-lg,
	'xl': $fa-size-scale-xl,
	'2xl': $fa-size-scale-2xl,
) !default;

$fa-li-width: 2em !default;
$fa-li-margin: $fa-li-width * fa-divide(5, 4) !default;

$fa-pull-margin: 0.3em !default;

$fa-primary-opacity: 1 !default;
$fa-secondary-opacity: 0.4 !default;

$fa-stack-vertical-align: middle !default;
$fa-stack-width: ($fa-fw-width * 2) !default;
$fa-stack-z-index: auto !default;

$fa-font-display: block !default;
$fa-font-path: '../../assets/font/fontAwesome' !default;

$fa-var-users: \f0c0;
$fa-var-user: \f007;
$fa-var-arrow-right: \f061;
$fa-var-arrow-left: \f060;
$fa-var-magnifying-glass: \f002;
$fa-var-search: \f002;
$fa-var-chevron-down: \f078;
$fa-var-store: \f54e;
$fa-var-chevron-left: \f053;
$fa-var-chevron-right: \f054;
$fa-var-check: \f00c;
$fa-var-thumbs-up: \f164;
$fa-var-notdef: \e1fe;

$fa-icons: (
	'users': $fa-var-users,
	'user': $fa-var-user,
	'arrow-right': $fa-var-arrow-right,
	'arrow-left': $fa-var-arrow-left,
	'magnifying-glass': $fa-var-magnifying-glass,
	'search': $fa-var-search,
	'chevron-down': $fa-var-chevron-down,
	'store': $fa-var-store,
	'chevron-left': $fa-var-chevron-left,
	'chevron-right': $fa-var-chevron-right,
	'check': $fa-var-check,
	'thumbs-up': $fa-var-thumbs-up,
	'notdef': $fa-var-notdef,
);

$fa-brand-icons: ();
