.stats-list {
	display: flex;
	width: 100%;
	margin: 0;
	padding: 0;
	flex-flow: row nowrap;
	justify-content: flex-start;
	align-items: center;
	list-style: none;

	@media (max-width: $md) {
		flex-flow: column nowrap;
		align-items: flex-start;
	}

	&--item {
		& + & {
			margin-left: 10px;

			@media (max-width: $md) {
				margin-top: 10px;
				margin-left: 0px;
			}
		}
	}
}
