.accordion {
	width: 100%;
	margin: 0;
	padding: 0;
	list-style: none;
	border-radius: 6px;
	overflow: hidden;

	&--item {
		border-top: 2px $BEIGE solid;
		&-toggle {
			display: flex;
			width: 100%;
			padding: 10px;
			flex-flow: row nowrap;
			justify-content: space-between;
			align-items: center;
			@include fontSize(20);
			font-weight: 700;
			background: $WHITE;
			border: none;
			cursor: pointer;

			&-active {
				.accordion--item-end {
					i {
						transform: rotate(180deg);
					}
				}
			}

			&-inactive {
				.accordion--item-end {
					i {
						transform: rotate(0deg);
					}
				}
			}
		}

		&-content {
			width: 100%;
			overflow: hidden;
			transition: height 0.5s ease-out;

			&-close {
				height: 0;
			}

			&-open {
				height: auto;
			}

			&-space {
				margin: 10px 0px 40px;
			}
		}

		&-title {
			display: inline-block;
			width: 100%;
			max-width: 400px;
			text-align: start;
		}

		&-notice {
			margin-left: 20px;
			flex-shrink: 0;
			font-weight: 400;

			@media (max-width: $md) {
				margin-left: 0;
				margin-top: 10px;
			}
		}

		&-start {
			display: flex;
			flex-grow: 1;
			flex-flow: row nowrap;
			align-items: center;
			justify-content: flex-start;
			text-align: start;

			@media (max-width: $md) {
				flex-flow: column nowrap;
				align-items: flex-start;
			}
		}

		&-end {
			margin-left: 20px;
			flex-shrink: 0;

			span {
				margin-right: 10px;
			}

			i {
				transition: all 0.5s ease-out;
			}
		}

		&:first-child {
			border-top: none;
		}
	}
}
