.notice {
	margin: 0;
	padding: 20px;
	border-radius: 6px;
	white-space: pre-line;

	p,
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		margin: 0;
		padding: 0;
	}

	&--success {
		background-color: $GREEN;
		color: $WHITE;
	}

	&--error {
		background-color: $RED;
		color: $WHITE;
	}

	&--info {
		background-color: $BLUE;
		color: $WHITE;
	}

	&--warning {
		background-color: $ORANGE;
		color: $WHITE;
	}
}
