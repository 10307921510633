.form {
	&-group {
		& + & {
			margin-top: 15px;
		}

		label {
			display: block;
			width: 100%;
			font-weight: 600;

			~ input,
			~ textarea,
			~ select {
				margin-top: 10px;
			}
		}

		input,
		textarea,
		select {
			display: block;
			width: 100%;
			padding: 5px;
			border: none;
			border-radius: 6px;
			resize: none;
		}

		&-submit {
			display: flex;
			flex-flow: row-reverse nowrap;
			align-items: center;
			justify-content: space-between;

			* + * {
				margin-right: 15px;
			}

			@media (max-width: $md) {
				flex-flow: column nowrap;

				* + * {
					margin-right: 0;
					margin-top: 15px;
				}
			}
		}

		&.form-group-submit {
			margin-top: 45px;
		}
	}

	&-row {
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		justify-content: flex-start;

		@media (max-width: $md) {
			flex-flow: column nowrap;
		}
	}

	&-col {
		flex: 1;

		& + & {
			margin-left: 15px;
		}

		@media (max-width: $md) {
			width: 100%;
			& + & {
				margin-left: 0;
				margin-top: 15px;
			}
		}
	}

	&-submit {
		&.center {
			display: flex;
			margin-top: 50px;
			flex-flow: row wrap;
			justify-content: center;
		}

		& + & {
			margin-top: 15px;
		}
	}

	&-error {
		margin-top: 5px;
		color: $RED;
		font-weight: 600;
		font-style: italic;
	}

	&-circular-button {
		display: flex;
		width: 60px;
		height: 60px;
		flex-flow: row nowrap;
		justify-content: center;
		align-items: center;
		background: $ORANGE;
		color: $WHITE;
		border-radius: 50%;
		border: none;
		cursor: pointer;
	}

	&-checkbox {
		display: flex;
		position: relative;
		flex-flow: row nowrap;
		align-items: center;

		input {
			position: absolute;
			top: 0;
			left: 0;
			opacity: 0;

			&:checked {
				~ .checkbox {
					color: $WHITE;
					background: $ORANGE;
				}
			}
		}

		.checkbox {
			display: inline-block;
			width: 25px;
			height: 25px;
			margin-right: 20px;
			background: $WHITE;
			color: $BEIGE;
			border-radius: 2px;
			text-align: center;
			line-height: 25px;
			font-size: 15px;
			cursor: pointer;
			transition: all 0.5s;
		}
	}
}
