.place-post {
	padding: 20px;
	background: $WHITE;
	border-radius: 6px;

	&--header {
		display: flex;
		flex-flow: row nowrap;
		justify-content: flex-start;
		align-items: center;
	}

	&--logo {
		width: 25px;
		height: 25px;
		margin: 0;
		border-radius: 5px;
		overflow: hidden;

		img {
			width: 100%;
			height: 100%;
			object-position: center;
			object-fit: cover;
		}
	}

	&--name {
		margin: 0 20px 0 10px;
		font-weight: 700;
	}

	&--date {
		margin: 0;
		@include fontSize(12);
		font-weight: 700;
	}

	&--list {
		margin: 0;
		padding: 0;
		list-style: none;

		&-item {
			& + & {
				margin-top: 20px;
			}
		}
	}

	&--delete {
		margin: 0;
		padding: 0;
		border: none;
		background: none;
		cursor: pointer;
		@include fontSize(12);
		font-weight: 700;
		color: $ORANGE;
		text-decoration: underline;
		cursor: pointer;
	}
}
