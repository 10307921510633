.footer {
	margin-top: 60px;
	background: $WHITE;

	.container {
		padding: 40px 10px;
	}

	&-logo {
		height: 40px;
		margin: 0 0 30px 0;
		img {
			height: 100%;
		}
	}

	&-nav {
		margin: 0;
		padding: 0;
		list-style: none;

		li {
			padding: 0;

			a {
				@include fontSize(20);
				font-weight: 700;
				text-decoration: none;
				color: $BLACK;
				transition: color 0.5s;

				&:hover {
					color: $ORANGE;
				}
			}

			& + li {
				margin-top: 10px;
			}
		}
	}

	&-legal {
		display: flex;
		padding: 0;
		margin-top: 70px;
		flex-flow: row wrap;
		justify-content: space-between;
		align-items: center;

		p {
			@include fontSize(15);
			color: $BLACK;
		}

		a {
			@include fontSize(15);
			color: $ORANGE;
			text-decoration: none;
		}
	}

	&-download {
		&-title {
			margin: 0 0 20px 0;
			@include fontSize(30);
			color: $ORANGE;
			font-weight: 700;
		}

		&-text {
			@include fontSize(20);
			font-weight: 700;
		}

		&-links {
			display: flex;
			margin: 0;
			padding: 0;
			flex-flow: row nowrap;
			list-style: none;
		}

		&-link {
			max-height: 40px;
			a {
				display: block;
				width: 100%;
				height: 100%;

				img {
					height: 100%;
				}
			}

			& + & {
				margin-left: 50px;
			}
		}
	}

	[class^='col-'] {
		& + [class^='col-'] {
			@media (max-width: $md) {
				margin-top: 50px;
			}
		}
	}
}
