*,
*::after,
*::before {
	box-sizing: border-box;
}

html {
	overflow-x: hidden;
}

body {
	min-height: 100vh;
	font-family: $mainFont;
	color: $BLACK;
	background-color: $BEIGE;
	@include fontSize(20);
	overflow-x: hidden;

	h1 {
		margin-top: 0;
	}

	a {
		color: $ORANGE;
	}

	.page {
		min-height: 100vh;

		&--title {
			@include fontSize(40);
			color: $ORANGE;

			@media (max-width: $md) {
				@include fontSize(30);
			}
		}

		&--subtitle {
			@include fontSize(30);
			font-weight: 700;
			color: $BLACK;

			@media (max-width: $md) {
				@include fontSize(20);
			}
		}

		&--section-title {
			@include fontSize(30);
			font-weight: 700;
			color: $ORANGE;

			i {
				margin-right: 20px;
			}

			@media (max-width: $md) {
				@include fontSize(20);
			}
		}
	}
}
