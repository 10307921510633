.section {
	padding: 100px 0;

	.container {
		border-radius: 10px;
	}

	&-white {
		.container {
			padding: 45px 140px;
			background-color: $WHITE;

			@media (max-width: $md) {
				padding: 45px 30px;
			}
		}
	}

	&--body {
		display: flex;
		flex-flow: row wrap;
		justify-content: center;
	}

	&--title {
		width: 100%;
		margin: 0 0 55px;
		text-align: center;
		color: $ORANGE;
		@include fontSize(40);
		font-weight: 700;

		@media (max-width: $md) {
			margin: 0 0 30px;
			@include fontSize(30);
		}
	}

	&--text {
		width: 100%;
		margin: 0px;
		text-align: center;
		color: $BLACK;
		@include fontSize(20);
		font-weight: 700;

		@media (max-width: $md) {
			@include fontSize(16);
		}

		& + * {
			margin-top: 55px;

			@media (max-width: $md) {
				margin-top: 30px;
			}
		}

		& + & {
			margin-top: 20px;
		}
	}
}
