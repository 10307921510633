.button {
	&--primary {
		@include button($color: $WHITE, $background-color: $ORANGE);
	}

	&--white {
		@include button($color: $ORANGE, $background-color: $WHITE);
	}

	&--black {
		@include button($color: $WHITE, $background-color: $BLACK);
	}
}
