.verification-code {
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: flex-start;

	&-digits {
		width: 100%;
		max-width: 500px;

		label {
			display: none;
		}

		input#vcode {
			margin-top: 0;
		}
	}

	&-digit {
		padding: 5px 10px;
		@include fontSize(40);
		font-weight: 700;
		border-radius: 10px;
		border: none;
	}

	button[type='submit'] {
		flex-shrink: 0;
		margin-left: 30px;
	}

	.loader {
		max-width: 150px;
		margin: 0 auto;
	}
}
