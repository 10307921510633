.navbar {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	padding: 30px 0;
	flex-flow: row nowrap;
	background: transparent;
	box-shadow: 0 0 0 transparent;
	transition: all 0.5s;
	z-index: 9999;

	.container {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	&-plain {
		background: $BEIGE;
		box-shadow: 0px 8px 12px -3px rgba(0, 0, 0, 0.39);
	}

	&-header {
		display: flex;
		margin-right: 20px;
		flex-flow: row nowrap;
		align-items: center;
		justify-content: center;
	}

	&-logo {
		display: block;
		width: 190px;

		img {
			width: 100%;
		}
	}

	&-links {
		display: flex;
		margin: 0;
		padding: 0;
		flex-flow: row nowrap;
		align-items: center;
		justify-content: flex-end;
		list-style: none;

		li {
			padding: 0;

			a {
				display: inline-block;
				width: 100%;
				color: $BLACK;
				text-decoration: none;
				transition: color 0.5s;
				&:hover {
					color: $ORANGE;
				}

				&.button--primary {
					@include button($color: $WHITE, $background-color: $ORANGE);
				}

				&.button--black {
					@include button($color: $WHITE, $background-color: $BLACK);
				}
			}

			& + li {
				margin-left: 20px;
			}
		}

		@media (max-width: $md) {
			position: fixed;
			top: 0;
			left: -110%;
			width: 100vw;
			height: 100vh;
			flex-flow: column nowrap;
			justify-content: center;
			align-items: center;
			background: $BEIGE;
			z-index: -1;
			transition: all 0.5s;

			li {
				& + li {
					margin-left: 0;
					margin-top: 10px;
				}

				a {
					@include fontSize(24);
				}
			}
		}

		&.navbar-links-open {
			left: 0;
		}
	}

	&-buttons {
		display: flex;
		padding: 0;
		flex-flow: row nowrap;
		align-items: center;
		list-style: none;

		li {
			padding: 0;

			.button--black {
				color: $WHITE;
			}

			.button--primary {
				color: $WHITE;
			}
		}

		@media (max-width: $md) {
			margin-top: 20px;

			li {
				& + li {
					margin-top: 0;
					margin-left: 10px;
				}
			}

			[class^='button--'] {
				@include fontSize(16);
			}
		}
	}

	&-button {
		display: flex;
		height: 30px;
		width: 40px;
		padding: 0;
		flex-flow: column nowrap;
		justify-content: space-between;
		background: transparent;
		border: none;

		&-line {
			display: block;
			width: 100%;
			height: 3px;
			background: $ORANGE;
			border-radius: 3px;
			transition: all 0.5s;
		}

		@media (min-width: #{$md + 1}) {
			display: none;
		}

		&.navbar-button-open {
			.navbar-button-line {
				&:first-child {
					transform: rotate(45deg) translate(6px, 13px);
				}
				&:nth-child(2) {
					opacity: 0;
				}
				&:nth-child(3) {
					transform: rotate(-45deg) translate(6px, -13px);
				}
			}
		}
	}

	li.navbar--dropdown {
		position: relative;
		padding: 15px 0;

		i {
			margin-right: 10px;
		}

		@media (min-width: #{$md + 1}) {
			&:hover {
				.navbar--dropdown-content {
					display: block;
				}
			}
		}

		.navbar--dropdown-content {
			position: absolute;
			display: none;
			top: 100%;
			right: 0;
			width: max-content;
			margin: 0;
			padding: 10px 20px;
			list-style: none;
			background: $WHITE;
			border-radius: 10px;

			li {
				width: 100%;

				a {
					color: $BLACK;
					text-align: end;

					&:hover {
						color: $ORANGE;
					}
				}

				& + li {
					padding: 0;
					margin: 10px 0 0;
				}
			}
		}
	}
}
