.stat-counter {
	margin: 0;
	@include fontSize(20);
	font-weight: 700;

	@media (max-width: $md) {
		@include fontSize(18);
	}

	i {
		margin-right: 5px;
	}
}
