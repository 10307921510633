@mixin button($color, $background-color) {
	display: inline-block;
	padding: 15px 20px;
	@include fontSize(16);
	font-weight: 700;
	text-align: center;
	text-decoration: none;
	color: $color;
	background: $background-color;
	border: none;
	border-radius: 10px;
	cursor: pointer;
	transition: all 0.5s;

	&:hover {
		color: $background-color;
		background: $color;
	}
}
