.text-with-image {
	&--side {
		display: flex;
		flex-flow: column nowrap;
		justify-content: center;

		&-content {
			max-width: 610px;

			@media (max-width: $md) {
				max-width: initial;
			}
		}
	}

	&--content {
		display: flex;
		flex-flow: column nowrap;
		justify-content: center;

		[class^='button'] {
			width: max-content;
			margin-top: 30px;
		}

		p {
			margin: 0;
			font-weight: 700;

			@include fontSize(20);

			& + p {
				margin-top: 20px;
			}

			@media (max-width: $md) {
				@include fontSize(16);
			}
		}

		&-large {
			p {
				@include fontSize(30);
				font-weight: 700;

				@media (max-width: $md) {
					@include fontSize(20);
				}
			}
		}
	}

	&--title {
		margin: 0 0 20px;
		color: $ORANGE;
		@include fontSize(30);
		font-weight: 700;
	}

	&--reverse {
		[class^='col-'] {
			&:first-child {
				order: 2;

				@media (max-width: $md) {
					order: 1;
				}
			}

			&:last-child {
				order: 1;

				@media (max-width: $md) {
					order: 2;
				}
			}
		}
	}
}
