.container {
	display: flex;
	width: 100%;
	padding: 0 10px;
	max-width: $containerWidth;
	margin: 0 auto;
	flex-wrap: wrap;
}

.row {
	display: grid;
	width: 100%;
	grid-template-columns: repeat(12, 1fr);
	column-gap: $gutter;

	@media (max-width: $sm) {
		grid-template-columns: repeat(4, 1fr);
	}
}

@mixin responsiveGrid($size: 'xs') {
	@for $i from 1 through $columns {
		.col-#{$size}-#{$i} {
			grid-column: span #{$i};
			padding: 0;
		}
	}
}

@include responsiveGrid;

@include respond-to(min-width, $sm) {
	@include responsiveGrid('sm');
}

@include respond-to(min-width, $md) {
	@include responsiveGrid('md');
}

@include respond-to(min-width, $lg) {
	@include responsiveGrid('lg');
}

@include respond-to(min-width, $xl) {
	@include responsiveGrid('xl');
}
